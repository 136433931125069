import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlight from "../components/ProductHighlight";
import ProductLinkListHorizonal from "../components/ProductLinkListHorizonal";

const Content = () => {
  const title = "Dr Pepper Addiction: Signs, Complications, and Recovery";
  const description = "Dr Pepper addiction and abuse is common in America. This site explores the different varieties of Dr Pepper and the effects of drinking.";

  return (
    <Layout>
      <Seo title={title} description={description} />
      <Container>
        <Row>
          <Col>
            <h1 className="mb-5">Dr Pepper Addiction: Quench your thirst, be responsible!</h1>
            <h2 className="mb-5">drpepperaddiction.com is an unofficial fan site dedicated to folks that have an indispensible craving for the Dr Pepper beverage.</h2>
            <p>
              Dr. Pepper was created by Charles Alderton, a pharmacist in Waco, Texas, in the 1800's. It was first introduced in the US in 1904. A variety of variants are available, including Diet Dr.
              Pepper, Dr. Pepper Zero Sugar and many more.
            </p>

            <p>
              Dr. Pepper contains caffeine which is highly addictive and as with all caffeinated beverages, a dependency or addiction to caffeine is likely with daily use. Drinking Dr Pepper every
              day, dependency is virtually certain.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>Now you can click any of the links below and get your Dr Pepper fix delivered right to your doorstep.</p>
          </Col>
        </Row>

        <ProductHighlight />

        <Row>
          <Col>
            <p>
              You can also find out information such as who owns dr pepper, how much caffeine is in dr pepper, what are the 23 flavors in dr pepper and much much more, so stick around and enjoy which
              drinking your favorite flavor of DP.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProductLinkListHorizonal title="Products" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;
